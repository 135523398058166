<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar back title="收银台" @onBackClick="onBackClick"/>
    <!--    <div v-if="test">{{ test }}</div>-->
    <!--  loading  -->
    <van-loading v-if="loading" color="#1989fa" size="24px" style="text-align: center;margin-top: 74px"/>
    <div v-else>
      <!--  订单编号  -->
      <div style="padding: 16px 0">
        <van-cell-group>
          <van-cell :value="paySignInfo.outTradeNo" title="订单编号"/>
        </van-cell-group>
      </div>
      <!--  您需要支付的费用 fee  -->
      <div style="padding: 16px 0">
        <van-cell-group>
          <van-cell :value="`￥${ fee }`" title="支付金额"/>
        </van-cell-group>
      </div>

      <!--  支付按钮  -->
      <van-button block style="background-color: #0a4b3d;color: white;font-size: 20px;width: 100%;height: 56px"
                  @click="onPayClick">
        {{ paySignInfo.isFree ? '点击下一步' : '立即支付' }}
      </van-button>
    </div>

  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import MallDao from '@/h5/doucan/network/MallDao'
import wx from 'weixin-jsapi'
import { Dialog } from 'vant'
import axios from 'axios'

export default {
  name: 'PayPage',
  components: { MyNavBar },
  data () {
    return {
      test: '',
      debug: false,
      loading: true,
      payInfo: null,
      isWeChatBrowser: false,
      fee: 0,
      appsecret: '',
      appid: '',
      code: '',
      openid: '',
      paySignInfo: null,
      testMobile: ['18008000808', '13610164743', '18520535303', '18501011010'],
      outTradeNo: '', // 动优支付系统里面的订单号 ordernum,
      mailInfo: null
    }
  },
  async mounted () {
    // this.from = this.$route.query.from
    // const phone = this.$route.query.phone
    // const name = this.$route.query.name
    // const openid = this.$route.query.openid

    this.payInfo = this.$store.state.mall.payInfo
    this.mailInfo = this.$store.state.mall.mailInfo
    console.log('收银台-支付信息', this.payInfo)
    console.log('收银台-收件信息', this.mailInfo)
    this.fee = this.payInfo.totalPrice
    this.checkWeChatBrowser()

    if (this.debug) {
      const openid = 'oqPFOw8XufttKl4XlcWhEwdI9hYg'
      this.openid = openid
      await this.createOrder()
      return
    }

    const isMiniapp = this.isWxMiniCodeWebviewEnv()
    console.log('是否微信小程序', isMiniapp)
    if (isMiniapp) {
      this.test = '小程序'
    } else {
      this.test = '非小程序'
    }

    // if (this.from === 'wxapp') {
    //   this.showTitleBar = false
    //   this.patientMobile = phone
    //   this.patientName = name
    //   this.openid = openid
    //   // this.envStatus = this.openid
    // } else {
    if (!this.isWxMiniCodeWebviewEnv()) {
      if (this.isWeChatBrowser) {
        await this.getAppid()
        // const openid = this.$store.state.user.openId
        // const openid = ''
        // if (openid) {
        //   this.openid = openid
        // await this.createOrder()
        // } else {
        await this.getMpOpenID()
        // await this.createOrder()
        // }
      }
    } else {
      this.openid = this.$store.state.wxAppOpenId
      this.$store.commit('user/setOpenId', this.openid)
    }

    // }
  },
  methods: {
    isWxMiniCodeWebviewEnv () {
      return navigator.userAgent.match(/miniprogram/i) || window.__wxjs_environment === 'miniprogram'
    },
    onBackClick () {
      // 返回上上页
      this.$router.go(-3)
    },
    async createOrder () {
      // 后台提交生成订单
      // 如果当前用户是登录用户，使用登录用户的token，否则使用统一下单用户的token作为标记
      // console.log('创建订单', this.payInfo)
      // console.log('this.$store.state.user', this.$store.state.user)
      // console.log('this.$store.state.user.profile', this.$store.state.user.profile)
      this.payInfo.token = this.$store.state.user.profile.token !== '' ? this.$store.state.user.profile.token : 'b684796b-87e5-466f-a835-8f80402249c4'
      this.payInfo.openid = this.openid
      const orderPayInfo = await MallDao.submitOrder(this.payInfo, this.isWxMiniCodeWebviewEnv())
      // console.log('预支付信息', orderPayInfo)
      if (orderPayInfo.code === 200) {
        if (orderPayInfo.isFree) {
          this.paySignInfo.isFree = true
          this.paySignInfo.outTradeNo = orderPayInfo.id
        } else {
          this.paySignInfo = orderPayInfo.data
        }
        this.loading = false
      } else {
        this.$toast('订单提交失败,请重试或联系客服(' + orderPayInfo.message + ')')
      }
    },
    onPayClick () {
      if (this.paySignInfo.isFree) {
        this.payInfo.outTradeNo = this.paySignInfo.outTradeNo

        this.$router.push({
          path: '/MallPayResultPage',
          query: {
            payInfo: this.payInfo,
            mailInfo: this.mailInfo
          }
        })
      } else {
        this.outTradeNo = this.paySignInfo.outTradeNo
        if (this.isWxMiniCodeWebviewEnv()) {
          this.paySignInfo.orderInfo = {
            outTradeNo: this.outTradeNo,
            orderCreateTime: this.orderCreateTime,
            remark: this.remark
          }
          this.payInfo.outTradeNo = this.paySignInfo.orderInfo.outTradeNo

          this.$store.commit('mall/setPayInfo', this.payInfo)
          this.$store.commit('mall/setMailInfo', this.mailInfo)
          // _that.$router.push({
          //   path: '/MallPayResultPage',
          //   query: {
          //     payInfo: _that.payInfo,
          //     mailInfo: _that.mailInfo
          //   }
          // })
          const wxJson = JSON.stringify(this.paySignInfo)
          const encodedParam = encodeURIComponent(wxJson)
          window.wx.miniProgram.navigateTo({
            url: `/pages/pay_submit_universe/pay_submit_universe?from=mall&param=${encodedParam}`
          })
        } else {
          // 调起微信支付
          wx.config({
            // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false
            debug: false,
            // 必填，公众号的唯一标识
            appId: this.paySignInfo.sign.appId,
            // 必填，生成签名的时间戳
            timestamp: this.paySignInfo.sign.timeStamp,
            // 必填，生成签名的随机串
            nonceStr: this.paySignInfo.sign.nonceStr,
            // 必填，签名
            signature: this.paySignInfo.sign.paySign,
            // 必填，需要使用的JS接口列表
            jsApiList: ['chooseWXPay']
          })
          wx.ready(() => {
            wx.checkJsApi({
              jsApiList: ['chooseWXPay'],
              success: function (res) {
                console.log('seccess')
                // console.log('hskdjskjk', res)
              },
              fail: function (res) {
                console.log('fail')
                // console.log(res)
              }
            })
            const _that = this
            wx.chooseWXPay({
              // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              timestamp: this.paySignInfo.sign.timeStamp,
              // 支付签名随机串，不长于 32 位
              nonceStr: this.paySignInfo.sign.nonceStr,
              // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              package: this.paySignInfo.sign.packageValue,
              // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              signType: this.paySignInfo.sign.signType,
              // 支付签名
              paySign: this.paySignInfo.sign.paySign,
              // 支付成功后的回调函数
              success: function (res) {
                if (res.errMsg === 'chooseWXPay:ok') {
                  // console.log('支付成功', res)
                  _that.payStatus = true
                  Dialog.alert({
                    message: '支付成功'
                  }).then(() => {
                    // on close
                    // todo 跳转到支付成功页面，显示支付凭证和提示
                    _that.payInfo.outTradeNo = _that.paySignInfo.outTradeNo

                    _that.$router.push({
                      path: '/MallPayResultPage',
                      query: {
                        payInfo: _that.payInfo,
                        mailInfo: _that.mailInfo
                      }
                    })
                  })
                } else {
                  Dialog({ message: JSON.stringify(res) })
                  _that.payStatus = false
                }
              },
              fail: function (res) {
                Dialog({ message: '支付失败:' + JSON.stringify(res) })
                _that.payStatus = false
              }
            })
          })
          wx.error((err) => {
            alert(err)
          })
        }
      }
    },
    checkWeChatBrowser () {
      const userAgent = navigator.userAgent.toLowerCase()
      this.isWeChatBrowser = /micromessenger/.test(userAgent)
    },
    async getAppid () {
      const url = '/quick/pay/appid'
      const res = await axios.get(url)
      // console.log('appid', res)
      this.appid = res.data.data.codeName
      this.appsecret = res.data.data.codeValue
    },
    async getMpOpenID () {
      const code = this.getUrlKey('code')
      if (code) {
        this.code = code
        await this.getOpenId(code)
      } else {
        this.getCodeApi()
        await this.getOpenId(code)
      }
    },
    async getOpenId (code) {
      if (this.isWxMiniCodeWebviewEnv()) {
        this.openid = this.$store.state.wxAppOpenId
        this.$store.commit('user/setOpenId', this.openid)
      } else {
        // this.info = '正在获取getOpenId'
        const url = '/quick/pay/openid?code=' + code

        axios
          .get(url)
          .then((res) => {
            // this.info = JSON.stringify(res)
            this.openid = res.data.data.openid
            // console.log('openid = ', res.data.data.openid)
            if (!this.openid) {
              this.getCodeApi()
              return
            }
            this.$store.commit('user/setOpenId', this.openid)
            // const profile = this.$store.state.apply20230001.profile
            // profile.openId = this.openid
            // this.$store.commit('apply20230001/update', profile)
          })
          .catch((err) => {
            console.log('err', err)
            this.getCodeApi()
          })
      }
    },
    getCodeApi () {
      const appid = 'wxcb56667c309e369b'
      const urlNow = encodeURIComponent(window.location.href)
      const scope = 'snsapi_base' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      // const scope = 'snsapi_userinfo' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      const url =
        'https://open.weixin.qq.com' +
        '/connect/oauth2/authorize?appid=' +
        appid +
        '&redirect_uri=' +
        urlNow +
        '&response_type=code&scope=' +
        scope +
        '#wechat_redirect'
      window.location.href = url
    },
    getUrlKey (name) {
      // 获取url 参数
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [undefined, ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    }
  },
  watch: {
    openid: {
      handler (newVal, oldVal) {
        if (this.openid) {
          this.createOrder()
        }
      }
    }
  },
  computed: {}
}
</script>
<style scoped>

</style>
