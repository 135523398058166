<template>
  <div>
    <base-title-bar-scroll-page ref="container" title="问卷记录">
      <!-- 问卷列表 -->
      <!-- 问卷列表 -->
      <van-cell-group>
        <van-cell
          v-for="item in questionnaires"
          :key="item.questionnaireId"
          :label="formatDate(item.submittedAt)"
          :title="item.questionnaireTitle"
          is-link
          @click="goToDetails(item.responseId)"
        >
          <template #right-icon>
            <van-icon name="arrow"/>
          </template>
        </van-cell>
      </van-cell-group>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import QuestionnaireDao from '@/h5/doucan/network/QuestionnaireDao'

export default {
  name: 'QuestionnaireHistoryPage',
  components: { BaseTitleBarScrollPage },
  data () {
    return {
      questionnaires: [] // 问卷列表
    }
  },
  async mounted () {
    const result = await QuestionnaireDao.getSubmitHistory(this.$route.query.openId)
    const newestQuestionnaires = result[result.length - 1]
    console.log('问卷历史记录', result)
    const answer = await QuestionnaireDao.getAnswersByResponseId(newestQuestionnaires.responseId)
    console.log('最新', answer)
    this.questionnaires = result
    this.hideLoading()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    // 格式化日期
    formatDate (date) {
      const d = new Date(date)
      return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(
        d.getDate()
      ).padStart(2, '0')} ${String(d.getHours()).padStart(2, '0')}:${String(
        d.getMinutes()
      ).padStart(2, '0')}`
    },
    // 跳转到问卷详情
    async goToDetails (id) {
      this.$toast(`跳转到问卷详情：${id}`)
      const answer = await QuestionnaireDao.getAnswersByResponseId(id)
      console.log('答案', answer)
      // 示例跳转逻辑，可替换为 Vue Router 的导航
      // this.$router.push(`/questionnaire/details/${id}`);
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
