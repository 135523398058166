import axios from 'axios'
import store from '@/h5/doucan/store'

const defaultToken = store.state.user.profile.token ? store.state.user.profile.token : 'b684796b-87e5-466f-a835-8f80402249c4'

const QuestionnaireDao = {
  async load (questionnaireId) {
    const url = `/api/questionnaires/v2/${questionnaireId}`
    const httpResp = await axios.get(url)
    const { data: resp } = httpResp
    console.log(`请求:${url}`, httpResp)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async submitAnswer (body) {
    const url = '/api/questionnaires/v2/response/submit'
    const { data: resp } = await axios.post(url, body, {
      headers: {
        token: defaultToken
      }
    })
    return resp
  },
  async getSubmitHistory (openId) {
    const url = `/api/questionnaires/v2/response/submitted/user/${openId}`
    const httpResp = await axios.get(url)
    const { data: resp } = httpResp
    console.log(`请求:${url}`, httpResp)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async getAnswersByResponseId (responseId) {
    const url = `/api/questionnaires/v2/answers/${responseId}`
    const httpResp = await axios.get(url)
    const { data: resp } = httpResp
    console.log(`请求:${url}`, httpResp)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }
}

export default QuestionnaireDao
