<template>
  <div style="background-color: #ffffff;width: 100vw;padding-bottom: 32px">
    <!--    <div style="position: relative">-->
    <!--      <van-swipe autoplay="3000" loop style="width: 100vw">-->
    <!--        <van-swipe-item v-for="(image, index) in images" :key="index" style="width: 100vw">-->
    <!--          <my-img :url="image" style="width: 100vw"/>-->
    <!--        </van-swipe-item>-->
    <!--      </van-swipe>-->
    <!--      <my-img style="width: 66%;position: absolute;top: 2px;left: 2px"-->
    <!--              url="https://cdn.keihong.tech/img/202408232318097.png"/>-->
    <!--      <div style="padding: 0 16px;position:absolute; bottom: -48px;left: 0" @click="onIntroClick">-->
    <!--        <my-img style="width: 100%;"-->
    <!--                url="https://cdn.keihong.tech/img/202411151731131.png"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div style="height: 56px"></div>-->
    <div v-if="test">{{ test }}</div>
    <div style="background-color: #fff;padding: 16px 16px 0 16px">
      <my-img style="width: 100%;" url="https://cdn.keihong.tech/img/202412091556356.png"
              @click.native="tapMdt"/>
    </div>
    <div style="padding: 16px">
      <div class="func-wrapper">
        <div class="left">
          <!--          <img alt="Long Image 1" src="https://cdn.keihong.tech/img/202412091604195.png"/>-->
          <!--          <img alt="Long Image 1" src="https://cdn.keihong.tech/img/202412091604410.png"/>-->
          <my-img url="https://cdn.keihong.tech/img/202412091604195.png" @click.native="tapMdt"/>
          <my-img url="https://cdn.keihong.tech/img/202412091604410.png" @click.native="tapKepuxuanjiaoMainPage"/>
        </div>
        <div class="right">
          <my-img url="https://cdn.keihong.tech/img/202412091604431.png" @click.native="tapHomeCare"/>
          <my-img url="https://cdn.keihong.tech/img/202412091604589.png" @click.native="tapHomeRehabilitation"/>
          <my-img url="https://cdn.keihong.tech/img/202412091604490.png" @click.native="tapPlan"/>
          <my-img url="https://cdn.keihong.tech/img/202412091605494.png" @click.native="tapDaka"/>
          <my-img url="https://cdn.keihong.tech/img/202412091605199.png" @click.native="tapMall"/>
        </div>
      </div>
    </div>
    <!--    <div style="padding: 16px">-->
    <!--      <ServiceImageButtonGroup-->
    <!--        :leftImage="'https://cdn.keihong.tech/img/202411151758264.png'"-->
    <!--        :rightItems="serviceItems1"-->
    <!--      />-->
    <!--      <div style="height: 16px"/>-->
    <!--      <ServiceImageButtonGroup-->
    <!--        :left-link="'#/MallMainPageNeimeng?hospital=宏乾健康&type=护理康复服务'"-->
    <!--        :leftImage="'https://cdn.keihong.tech/img/202411151818876.png'"-->
    <!--        :rightItems="serviceItems2"-->
    <!--      />-->
    <!--      <div style="height: 16px"/>-->
    <!--      <ServiceImageButtonGroup-->
    <!--        :leftImage="'https://cdn.keihong.tech/img/202411151821222.png'"-->
    <!--        :rightItems="serviceItems3"-->
    <!--      />-->
    <!--    </div>-->
    <div style="height: 32px"></div>
    <div class="doctor-list-wrapper">
      <div style="margin-bottom: 16px;font-size: 18px">全部专家</div>
      <my-img style="width: 100%;margin-bottom: 16px" url="https://cdn.keihong.tech/img/202412091637331.png"/>
      <my-img style="width: 100%;margin-bottom: 16px" url="https://cdn.keihong.tech/img/202412091639275.png"/>
    </div>

    <div style="margin-top: 64px;font-size: 12px;color: #505050;text-align: center;margin-bottom: 16px">
      技术支持：动优科技
    </div>
  </div>

</template>
<script>
import MyImg from '@/h5/doucan/components/MyImg.vue'
import ServiceImageButtonGroup from '@/h5/doucan/components/ServiceImageButtonGroup.vue'

export default {
  components: {
    ServiceImageButtonGroup,
    MyImg
  },
  data () {
    return {
      test: '',
      images: [
        'https://cdn.keihong.tech/img/202411161337244.jpg',
        'https://cdn.keihong.tech/img/202411161337784.jpg',
        'https://cdn.keihong.tech/img/202411161337195.jpg'
      ],
      serviceItems1: [
        {
          image: 'https://cdn.keihong.tech/img/202411151803944.png',
          title: '',
          link: '#/LoginCheckPage?route=/JeilongDingcanPage'
        },
        {
          image: 'https://cdn.keihong.tech/img/202411151759609.png',
          title: '',
          link: '#/PingTuanHomePage'
        },
        {
          image: 'https://cdn.keihong.tech/img/202411151803888.png',
          title: '',
          link: '#/MallMainPageNeimeng?hospital=宏乾健康&mode=动优辅具&type=生活超市'
        }
      ],
      serviceItems2: [
        {
          image: 'https://cdn.keihong.tech/img/202411151819825.png',
          title: '',
          link: '#/MallMainPageNeimeng?hospital=宏乾健康&type=护理康复服务&select=上门护理'
        },
        {
          image: 'https://cdn.keihong.tech/img/202411161239029.png',
          title: '',
          link: '#/MallMainPageNeimeng?hospital=宏乾健康&type=护理康复服务&select=上门康复'
        },
        {
          image: 'https://cdn.keihong.tech/img/202411161240018.png',
          title: '',
          link: '#/MallMainPageNeimeng?hospital=宏乾健康&type=护理康复服务&select=社区康复'
        }
      ],
      serviceItems3: [
        {
          image: 'https://cdn.keihong.tech/img/202411151820537.png',
          title: '',
          link: '#/LoginCheckPage?route=/mdt/home'
        },
        {
          image: 'https://cdn.keihong.tech/img/202411151820016.png',
          title: '',
          link: '#/LoginCheckPage?route=/planListPage'
        },
        {
          image: 'https://cdn.keihong.tech/img/202411151821047.png',
          title: '',
          link: '#/LoginCheckPage?route=/PatientDataHomePage'
        }
      ]
    }
  },
  mounted () {
    if (this.$route.query.openid) {
      this.$store.commit('setIsWxapp', true)
      this.$store.commit('setWxAppOpenId', this.$route.query.openid)
      // this.test = this.$store.state.wxAppOpenId
    }
    if (this.$route.query.hospitalId) {
      console.log('hospitalId', this.$route.query.hospitalId)
      console.log('phone', this.$route.query.phone)
    }
    // this.hideLoading()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    onIntroClick () {
      // 跳转url https://mp.weixin.qq.com/s/VM8IMKPS2wlMS-xU23SxGQ
      window.location.href = 'https://mp.weixin.qq.com/s/VM8IMKPS2wlMS-xU23SxGQ'
    },
    tapMdt () {
      const route = '/mdt/home'
      // this.$store.commit('setBack2', true)
      const isLogin = this.$store.state.user.profile.isLogin
      if (isLogin) {
        if (window.wx?.miniProgram) {
          window.wx.miniProgram.navigateTo({
            url: `/pages/mdt_list/mdt_list?from=web&phone=${this.$store.state.user.profile.phone}`
          })
        } else {
          this.$router.push(route)
        }
      } else {
        this.$router.push('/login/index')
      }
    },
    tapPlan () {
      const route = '/planListPage'
      // this.$store.commit('setBack2', true)
      const isLogin = this.$store.state.user.profile.isLogin
      if (isLogin) {
        this.$router.push(route)
      } else {
        this.$router.push('/login/index')
      }
    },
    tapDaka () {
      const route = '/PatientDataHomePage'
      // this.$store.commit('setBack2', true)
      const isLogin = this.$store.state.user.profile.isLogin
      if (isLogin) {
        this.$router.push(route)
      } else {
        this.$router.push('/login/index')
      }
    },
    tapMall () {
      const route = '/MallMainPageNeimeng?hospital=宏乾健康&mode=动优辅具&type=生活超市'
      // this.$store.commit('setBack2', true)
      const isLogin = this.$store.state.user.profile.isLogin
      if (isLogin) {
        this.$router.push(route)
      } else {
        this.$router.push('/login/index')
      }
    },
    tapHomeCare () {
      const route = '/MallMainPageNeimeng?hospital=宏乾健康&type=护理康复服务&select=上门护理&notShowMenu=true'
      // this.$store.commit('setBack2', true)
      const isLogin = this.$store.state.user.profile.isLogin
      if (isLogin) {
        this.$router.push(route)
      } else {
        this.$router.push('/login/index')
      }
    },
    tapHomeRehabilitation () {
      const route = '/MallMainPageNeimeng?hospital=宏乾健康&type=护理康复服务&select=上门康复&notShowMenu=true'
      // this.$store.commit('setBack2', true)
      const isLogin = this.$store.state.user.profile.isLogin
      if (isLogin) {
        this.$router.push(route)
      } else {
        this.$router.push('/login/index')
      }
    },
    tapKepuxuanjiaoMainPage () {
      const route = '/KepuxuanjiaoMainPage'
      // this.$store.commit('setBack2', true)
      const isLogin = this.$store.state.user.profile.isLogin
      if (isLogin) {
        this.$router.push(route)
      } else {
        this.$router.push('/login/index')
      }
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>
.func-wrapper {
  display: flex;
  gap: 8px;
  height: 400px; /* 设置父容器高度 */
}

.left,
.right {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.left {
  flex: 7.6;
}

.right {
  flex: 11.1;
}

.left img,
.right img {
  width: 100%;
  object-fit: cover; /* 保证图片覆盖区域 */
  flex: 1; /* 均分父容器高度 */
}

.left img:first-child {
  flex: 1 1 50%; /* 第一个长图占据父容器的50% */
}

.right img {
  flex: 1 1 20%; /* 每个短图占据父容器的20% */
}

.doctor-list-wrapper {
  opacity: 1;
  background: linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 10%, #F9F9F9 89%, #FFFFFF 100%);
  padding: 16px;
}
</style>
