<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar :back-text="backText" back style="position:fixed;top: 0;width: 100%" title="辅具租赁"
              @onBackClick="onBackClick"/>
    <div style="margin-top: 46px">
      <div style="display: flex;">
        <div style="width: 30vw;background-color: #f3f3f3;height: 95vh">
          <div>
            <div v-for="(item) in catalogList" :key="item.id"
                 style="height: 56px;text-align: center;line-height: 56px;display: flex;flex-direction: row"
                 @click="onCatalogClick(item)">
              <div :style="{backgroundColor: item.selected?'#00b74f':'#4b4b4b'}" style="height: 56px;width: 8px;"></div>
              <div style="flex: 1"> {{
                  item.name
                }}
              </div>
            </div>
          </div>
        </div>
        <div
          style="width: 70vw;background-color: #ffffff;height: 95vh; /* 设置右侧高度为视口高度 */overflow: hidden">
          <!--  商品列表 使用vant -->
          <!--    <van-list :finished="finished" finished-text="没有更多了">-->
          <div style=" max-height: 100%;overflow-y: auto; /* 允许内容溢出时显示垂直滚动条 */">
            <div v-for="(item, index) in goods" :key="index">
              <van-card
                :desc="item.brief"
                :origin-price="item.price+50"
                :thumb="item.smallPicUrl"
                :title="item.name"
                @click="handleGoodClick(item)"
              >
                <template #price>
                  <van-tag plain type="danger">¥{{ item.rentPrice }}元</van-tag>
                  <!--                  <van-tag plain type="danger">¥{{ item.rentPrice }}元 / 天</van-tag>-->
                </template>
                <template #footer>
                  <van-button size="mini">查看详情</van-button>
                  <!--            <van-button size="mini">按钮</van-button>-->
                </template>
              </van-card>
              <div style="height: 16px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="showContract" close-icon="https://cdn.keihong.tech/img/202311181839262.png" closeable
               position="bottom"
               style="width: 100%">
      <div style="height: 90vh">
        <div style="text-align: center;padding: 16px">租赁流程</div>
        <div style="height: 70vh;overflow-y: scroll">
          <img src="https://cdn.keihong.tech/img/202311181843883.png" style="width: 100%;display: block"/>
          <img src="https://cdn.keihong.tech/img/202311181843883.png" style="width: 100%;display: block"/>
        </div>
        <!--     我已同意《电子租赁合同》     -->
        <div style="display: flex;justify-content: center;align-items: center;padding: 16px">
          <!--            <van-icon name="circle"/>-->
          <van-button type="danger" @click="handleClickAcceptContract">我已知晓</van-button>
        </div>

      </div>
    </van-popup>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import MallDao from '@/h5/doucan/network/MallDao'

export default {
  name: 'MallRentMainPageNeimeng',
  components: { MyNavBar },
  data () {
    return {
      showMenu: false,
      showContract: true,
      finished: false,
      loading: false,
      from: '',
      backRoute: '',
      goods: [],
      backText: '',
      fromToolKepu: false,
      hospitalName: '',
      catalogList: [],
      currentCatalog: null
    }
  },
  mounted () {
    // 读取query参数
    const query = this.$route.query
    if (query.from === 'toolkepu') {
      this.fromToolKepu = true
      this.backText = '返回'
    } else {
      this.backText = '康复圈'
    }
    // if (this.from) {
    //   if (this.from === 'square') {
    //     // 从康复圈页面进来（常规渠道）
    //     this.backRoute = '' // 返回按钮直接是返回
    //     return
    //   }
    //   // 从独立的商城二维码进来
    //   if (this.from === 'qrCode') {
    //     return
    //   }
    //   // 从科普文章进来(有专门的产品)
    //   if (this.from === 'article') {
    //
    //   }
    // }
    this.loadCatalogs()
    // this.loadGoods()
  },
  methods: {
    handleClickAcceptContract () {
      this.showContract = false
    },
    onBackClick () {
      if (this.fromToolKepu) {
        this.$router.back()
        return
      }
      console.log('返回')
      this.$router.replace('/home/square')
    },
    onCatalogClick (item) {
      this.currentCatalog.selected = false
      item.selected = true
      this.currentCatalog = item
      this.loadGoods()
    },
    loadCatalogs () {
      this.hospitalName = this.$store.state.user.qrCodeHospital
      if (!this.hospitalName) {
        this.hospitalName = '动优康复'
      }
      MallDao.cataloglistRent(this.hospitalName).then(res => {
        console.log('catalogs', res)
        res.forEach(item => {
          item.selected = false
        })
        res[0].selected = true
        this.currentCatalog = res[0]
        this.catalogList = res
        this.loadGoods()
      })
    },
    async loadGoods () {
      console.log('读取商品目录下的产品： cata id = ' + this.currentCatalog.id)
      this.goods = await MallDao.listByCatalog(this.currentCatalog.id)
      console.log('goods', this.goods)
    },
    handleGoodClick (item) {
      // 跳转到MallDetailPage: /MallDetailPage?goodId=item.id
      this.$router.push('/MallRentDetailPage?from=malllist&goodId=' + item.id)
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
