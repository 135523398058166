<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div style="display: flex;height: 100vh;width: 100vw;flex-direction: column">
    <MyNavBar v-show="showTitleBar" :back="back" :back-text="backText" :title="title" @onBackClick="onBackClick"/>
    <div v-if="loading" style="text-align: center;padding-top: 5vh">
      <van-loading color="#1989fa" size="48px" type="spinner"/>
      <div style="font-size: 18px;margin-top: 16px;color: #575757">加载中</div>
    </div>
    <div v-else-if="empty"
         style="text-align: center;margin-top: 10vh;display: flex;flex-direction: column;align-items: center">
      <img alt="img" src="https://cdn.keihong.tech/img/202311261630956.png" style="width: 50vw;display: block">
      <div style="font-size: 18px;color: #606060">您当前页面下没有数据哦</div>
    </div>
    <div v-else style="width: 100vw;flex: 1;overflow-y: scroll;background-color: #efefef;padding: 0">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../components/MyNavBar'

export default {
  name: 'BaseTitleBarScrollPage',
  components: { MyNavBar },
  props: {
    showTitleBar: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '标题栏'
    },
    back: {
      type: Boolean,
      default: false
    },
    backText: {
      type: String,
      default: '返回'
    }
  },
  data () {
    return {
      loading: true,
      empty: false

    }
  },
  mounted () {

  },
  methods: {
    startLoading () {
      this.loading = true
    },
    stopLoading () {
      this.loading = false
    },
    showEmpty () {
      this.empty = true
    },
    hideEmpty () {
      this.empty = false
    },
    onBackClick () {
      // 父组件调用
      this.$emit('onBackClick')
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
