<template>
  <div>
    正在检查账号...
  </div>
</template>
<script>

export default {
  name: 'LoginCheckPage',
  data () {
    return {}
  },
  mounted () {
    this.$store.commit('setBack2', true)
    const isLogin = this.$store.state.user.profile.isLogin
    if (isLogin) {
      if (this.$route.query.route === '/mdt/home' && window.wx?.miniProgram) {
        window.wx.miniProgram.navigateTo({
          url: `/pages/mdt_list/mdt_list?from=web&phone=${this.$store.state.user.profile.phone}`
        })
        this.$router.back()
        return
      }
      if (this.$route.query.route) {
        this.$router.push(this.$route.query.route)
        return
      }
      if (this.$route.query.method) {
        switch (this.$route.query.method) {
          case 'planList':
            this.$router.push('/planListPage')
            break
        }
      }
    } else {
      this.$router.push('/login/index')
    }
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
