import axios from 'axios'
import store from '@/h5/doucan/store'

const defaultToken = store.state.user.profile.token ? store.state.user.profile.token : 'b684796b-87e5-466f-a835-8f80402249c4'

const BookingMealDao = {
  // async add (body) {
  //   const url = `/booking/meal/${questionnaireId}`
  //   const httpResp = await axios.get(url)
  //   const { data: resp } = httpResp
  //   console.log(`请求:${url}`, httpResp)
  //   const {
  //     code,
  //     data
  //   } = resp
  //   if (code !== 200 || !data) {
  //     return null
  //   }
  //   return data
  // },
  async add (body) {
    const url = '/booking/meal/add'
    const { data: resp } = await axios.post(url, body, {
      headers: {
        token: defaultToken
      }
    })
    return resp
  },
  async getTodayList () {
    const url = '/booking/meal/list'
    const httpResp = await axios.get(url)
    const { data: resp } = httpResp
    console.log(`请求:${url}`, httpResp)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }
}

export default BookingMealDao
