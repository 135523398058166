import axios from 'axios'
import store from '../store'

const defaultToken = store.state.user.profile.token ? store.state.user.profile.token : 'b684796b-87e5-466f-a835-8f80402249c4'

const MallDao = {
  // 产品类目列表
  async cataloglist (hospitalName) {
    const url = '/mallGoods/catalogueList'
    const { data: resp } = await axios.get(url, {
      params: { hospitalName: hospitalName },
      headers: {
        token: defaultToken
      }
    })
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async search (key) {
    const url = '/mallGoods//search/good'
    const { data: resp } = await axios.get(url, {
      params: { keyword: key },
      headers: {
        token: defaultToken
      }
    })
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async cataloglistRent (hospitalName) {
    const url = '/mallGoods/catalogueList/rent'
    const { data: resp } = await axios.get(url, {
      params: { hospitalName: hospitalName },
      headers: {
        token: defaultToken
      }
    })
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  // 首页从0开始
  async list () {
    const url = '/mallGoods/all'
    const { data: resp } = await axios.get(url, {
      headers: {
        token: defaultToken
      }
    })

    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async listByCatalog (catalogId) {
    const url = '/mallGoods/byCatalogue?catalog_id=' + catalogId
    const { data: resp } = await axios.get(url, {
      headers: {
        token: defaultToken
      }
    })

    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async findById (goodId) {
    const url = '/mallGoods/goodById?good_id=' + goodId
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async addAddress (req) {
    const url = '/mallDeliveryAddress/add'
    const { data: resp } = await axios.post(url, req, {
      headers: {
        token: defaultToken
      }
    })
    return resp
  },
  async submitOrder (req, isMiniapp = false) {
    const url = '/mallOrder/purchase/wxmp' + (isMiniapp ? '/miniapp' : '')
    const { data: resp } = await axios.post(url, req, {
      headers: {
        token: defaultToken
      }
    })
    return resp
  },
  async getOrders (phone) {
    const url = '/mallOrder/details/byPhone?phone=' + phone
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async getOrderDetail (orderID) {
    const url = '/mallOrder/detail?order_id=' + orderID
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }

}
export default MallDao
