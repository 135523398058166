<template>
  <div>
    <van-cell-group>
      <!--      <van-cell :label="question.description" :title="`${question.order}、${question.content}`"/>-->
      <van-cell :label="question.description">
        <template v-slot:title>
          <span v-if="question.required" style="color: red">*</span>
          {{ question.order }}、{{ question.content }}
        </template>
      </van-cell>

      <div style="padding: 0 16px">
        <!-- 文本输入 -->
        <van-field
          v-if="question.type === 'text'"
          v-model="localValue"
          :placeholder="question.notePlaceholder || '请输入内容'"
          autosize
          class="custom-field"
          clearable
          type="textarea"
        />

        <!-- 单选 -->
        <van-radio-group
          v-if="question.type === 'single_choice'"
          v-model="localValue"
          class="custom-radio-group"
        >
          <van-radio
            v-for="option in question.options"
            :key="option.id"
            :name="option.id"
            class="custom-radio"
          >
            {{ option.content }}
          </van-radio>
        </van-radio-group>

        <!-- 多选 -->
        <van-checkbox-group
          v-if="question.type === 'multiple_choice'"
          v-model="localValue"
          class="custom-checkbox-group"
        >
          <van-checkbox
            v-for="option in question.options"
            :key="option.id"
            :name="option.id"
            class="custom-checkbox"
          >
            {{ option.content }}
          </van-checkbox>
        </van-checkbox-group>

        <!-- 文件上传 -->
        <div v-if="question.type === 'file'">
          <van-uploader
            v-model="localValue"
            :after-read="afterRead"
            :before-read="beforeRead"
            :max-count="50"
            accept="image/*,application/pdf"
          />
        </div>
      </div>
    </van-cell-group>
  </div>
</template>

<script>
import { Cell, CellGroup, Checkbox, CheckboxGroup, Field, Radio, RadioGroup, Uploader } from 'vant'

export default {
  props: {
    question: {
      type: Object,
      required: true
    },
    value: [String, Number, Array, File] // 修改为允许 String, Number, Array 类型
  },
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Uploader.name]: Uploader
  },
  data () {
    return {
      localValue: this.value
    }
  },
  watch: {
    localValue (newValue) {
      this.$emit('input', newValue)
    }
  },
  mounted () {
    // console.log('question item this.localValue', this.localValue)
    // console.log('question item this.value', this.value)
  },
  methods: {
    renderTitle () {
      const requiredMark = this.question.required ? '<span style="color: red">*</span> ' : ''
      return `${requiredMark}${this.question.order}、${this.question.content}`
    },
    beforeRead (file) {
      if (file.type !== 'application/pdf' && !file.type.startsWith('image/')) {
        this.$toast('仅支持图片或PDF格式')
        return false
      }
      return true
    },
    afterRead (file) {
      console.log('afterRead', file)
      console.log('file', this.localValue)
    }
  }
}
</script>
<style scoped>
/* 增加单选和多选选项的行间距 */
.custom-radio-group .custom-radio,
.custom-checkbox-group .custom-checkbox {
  margin-bottom: 12px; /* 设置选项的行间距 */
}

.custom-field {
  background-color: #ffffff; /* 白色背景 */
  border-radius: 8px; /* 圆角 */
  padding: 10px; /* 内边距 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 阴影效果 */
}

.custom-field .van-field__control {
  line-height: 1.5; /* 调整行高 */
  min-height: 60px; /* 最小高度（确保显示三行） */
  word-wrap: break-word; /* 强制换行 */
  overflow-wrap: break-word; /* 防止长单词溢出 */
  resize: none; /* 禁止用户手动调整大小 */
}
</style>
