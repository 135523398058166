<template>
  <div>
    <!--    <div class="title-bar">付款</div>-->
    <base-title-bar-scroll-page ref="container" :back="true" :show-title-bar="showTitleBar" back-text="主页"
                                title="支付"
                                @onBackClick="onBackClick">

      <div v-if="hospital" style="margin: 16px;background-color:white;border-radius: 16px;padding-top: 16px">
        <div v-if="envStatus">{{ envStatus }}</div>
        <div style="font-weight: bold;font-size: 20px;text-align: center">远程康复服务单</div>
        <div style="font-size: 14px;text-align: center;margin-bottom: 16px">{{ hospital.name }}</div>
        <div class="divider"></div> <!-- Divider line -->
        <div v-if="hospital.descUrl">
          <my-img :url="hospital.descUrl" style="width: 100%;"/>
        </div>
        <div v-else-if="!hospital.notShowPic">
          <my-img style="width: 100%;" url="https://cdn.keihong.tech/img/202408241444172.png"/>
        </div>
        <div v-if="hospital.desc" class="description" style="margin: 16px;font-size: 14px;">
          {{ hospital.desc }}
        </div>
        <!--        <div id="payTitle"-->
        <!--             style="display: flex;background-color: white;border-radius: 16px;padding: 8px;align-items: center;">-->
        <!--          &lt;!&ndash;          <img alt="Logo" class="logo" src="https://cdn.keihong.tech/img/202312211710121.png">&ndash;&gt;-->
        <!--          &lt;!&ndash;          <div class="institution-name">{{ hospital }}</div>&ndash;&gt;-->
        <!--        </div>-->

        <!--        <div class="inputBoxWrapper">-->
        <!--          <van-field-->
        <!--            v-model="amount"-->
        <!--            class="inputBox"-->
        <!--            label="付款金额"-->
        <!--            placeholder="请输入付款金额"-->
        <!--            type="number"-->
        <!--          >-->
        <!--            <template #button>-->
        <!--              <span>元</span>-->
        <!--            </template>-->
        <!--          </van-field>-->
        <!--        </div>-->
        <div style="box-sizing: border-box;padding: 16px">
          <van-field label="服务周期选择" required>
            <template #input>
              <div style="width: 100%;">
                <van-stepper v-model="form.count" class="right" integer min="1" style="padding: 0;margin: 0"/>
                <van-cell :value="form.count" title="服务周期">
                  <!--修改单位为周-->
                  <template #right-icon>
                    <span v-if="isExpert" style="font-size: 12px">次</span>
                    <span v-else-if="hospital.numberUnit" style="font-size: 12px">{{ hospital.numberUnit }}</span>
                    <span v-else style="font-size: 12px">个月</span>
                  </template>
                </van-cell>
                <van-cell :value="form.price" title="服务单价">
                  <!--修改单位为元-->
                  <template #right-icon>
                    <span v-if="isExpert" style="font-size: 12px">次/元</span>
                    <span v-else-if="hospital.priceUnit" style="font-size: 12px">{{ hospital.priceUnit }}</span>
                    <span v-else style="font-size: 12px">元/月</span>
                  </template>
                </van-cell>
                <van-cell :value="form.count * form.price  " title="总费用">
                  <!--修改单位为元-->
                  <template #right-icon>
                    <span style="font-size: 12px">元</span>
                  </template>
                </van-cell>
                <!--            <van-cell :value="form.count * form.price * 0.5" title="实际支付">-->
                <!--              &lt;!&ndash;修改单位为元&ndash;&gt;-->
                <!--              <template #right-icon>-->
                <!--                <span style="font-size: 12px">元</span>-->
                <!--              </template>-->
                <!--            </van-cell>-->
              </div>
            </template>
          </van-field>

          <!--          <van-field v-model="selectedHospital" label="选择医院" placeholder="请选择医院" @change="updateDepartments"/>-->
          <!--          <div style="color: #6e6e6e">请选择您所在的科室</div>-->
          <van-field label="病因" required>
            <template #input>
              <div style="width: 100%;text-align: right">
                <van-radio-group v-model="selectedSick" class="right"
                                 style="display: flex;flex-direction: row;flex-wrap: wrap">
                  <van-radio v-for="sick in sicks" :key="sick"
                             :name="sick"
                             style="margin-top: 8px;margin-left: 8px">{{
                      sick
                    }}
                  </van-radio>
                </van-radio-group>
              </div>
            </template>
          </van-field>
          <van-field label="科室" required>
            <template #input>
              <div style="width: 100%;text-align: right">
                <van-radio-group v-model="selectedDepartment" class="right"
                                 style="display: flex;flex-direction: row;flex-wrap: wrap"
                                 @change="onDepartmentSelected">
                  <van-radio v-for="department in hospital.departments" :key="department.id"
                             :name="department"
                             style="margin-top: 8px;margin-left: 8px">{{
                      department.name
                    }}
                  </van-radio>
                </van-radio-group>
              </div>
            </template>
          </van-field>
          <van-field v-if="selectedDepartment" label="服务管理医生/治疗师" required>
            <template #input>
              <div style="width: 100%;text-align: right">
                <van-radio-group v-model="selectedDoctor" class="right"
                                 style="display: flex;flex-direction: row;flex-wrap: wrap">
                  <van-radio v-for="doctor in selectedDepartment.doctors" :key="doctor"
                             :name="doctor"
                             style="margin-top: 8px;margin-left: 8px">{{
                      doctor
                    }}
                  </van-radio>
                </van-radio-group>
              </div>
            </template>
          </van-field>
        </div>
        <div class="input-wrapper">
          <van-field
            v-model="patientName"
            label="姓名"
            placeholder="请输入姓名"
            required
            type="text"
          />
          <van-field
            v-model="patientMobile"
            label="手机号"
            placeholder="请输入手机号"
            required
            type="tel"
          />
        </div>
        <!--        <div class="textarea-wrapper">-->
        <!--          <van-field-->
        <!--            v-model="remarks"-->
        <!--            placeholder="请输入备注"-->
        <!--            rows="5"-->
        <!--            style=" border: 1px solid #ccc;border-radius: 8px;"-->
        <!--            type="textarea"-->
        <!--          />-->
        <!--        </div>-->
        <div class="button-wrapper">
          <van-button style="width: 100%;margin-bottom: 16px" type="primary" @click="submitPayment">立即支付
          </van-button>
        </div>
      </div>
      <div style="text-align: center;font-size: 14px;color: #494949">技术支持：动优远程康复服务云平台</div>
    </base-title-bar-scroll-page>
    <van-popup v-model="showPopup" :close-on-click-overlay="false" class="popup-wrapper" position="bottom">
      <div style="padding: 16px;text-align: center">
        <img alt="Success Logo" class="success-logo" src="https://cdn.keihong.tech/img/202312211813200.png">
        <div class="success-message">支付成功</div>
        <div style=" text-align: center;font-size: 14px;margin: 20px 0;">订单编号：{{ outTradeNo }}</div>
        <div style=" text-align: center;font-size: 14px;margin: 20px 0;">{{ orderCreateTime }}</div>
        <div style=" text-align: center;font-size: 18px;margin: 20px 0;">支付服务</div>
        <div style=" font-size: 14px;margin: 20px 0;text-align: left">{{ this.remark }}</div>
        <div style="color: red;font-size: 20px;font-weight: bold">
          请截图当前页面
        </div>
        <div style="color: #272727;text-align: left;font-size: 18px">
          请截图当前页面，前往康复治疗部联系康复治疗师领取居家康复训练视频方案，有疑问可联系康复助理了解详情
        </div>
        <van-button style="width: 100%;margin-top: 16px" type="primary" @click="refreshPage">确定</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>

import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import { Dialog, Notify, Toast } from 'vant'
import WxPayDao from '@/h5/doucan/network/WxPayDao'
import wx from 'weixin-jsapi'
import axios from 'axios'
import DateUtils from '@/utils/DateUtils'
import MyImg from '@/h5/doucan/components/MyImg.vue'

export default {
  name: 'QuickPayNormalPage',
  components: {
    MyImg,
    BaseTitleBarScrollPage
  },
  data () {
    return {
      envStatus: '',
      from: '',
      showTitleBar: true,
      showPopup: false,
      hospital: null,
      hospitalId: null,
      isExpert: false,
      amount: '',
      remarks: '',
      patientName: '',
      patientMobile: '',
      testMobile: ['18008000808', '13610164743', '18520535303', '18501011010'],
      openId: '',
      payStatus: false,
      appsecret: '',
      appid: '',
      code: '',
      openid: '',
      paySignInfo: null,
      outTradeNo: '', // 动优支付系统里面的订单号 ordernum
      remark: '',
      hospitals: {
        16: {
          name: '广州中医药大学顺德医院',
          departments: [{
            id: 1,
            name: '康复医学科',
            doctors: ['潘志明', '陈竞豪', '范伟棋', '钟景涛']
          }
          ]
        },
        52: {
          name: '乐扬运动康复',
          descUrl: 'https://cdn.keihong.tech/img/202409112244824.png',
          price: 800,
          priceUnit: '元/月',
          numberUnit: '个月',
          departments: [{
            id: 1,
            name: '默认',
            doctors: ['默认']
          }
          ]
        },
        14: {
          name: '暨南大学附属第一医院',
          price: 500,
          unit: '元/次',
          notShowPic: true,
          desc: '【 专家接诊服务费 】',
          departments: [{
            id: 1,
            name: '康复医学科',
            doctors: ['严嘉健', '其他医生']
          }
          ]
        },
        46: {
          name: '东莞市桥头医院',
          departments: [{
            id: 1,
            name: '康复医学科',
            doctors: ['医生A', '医生B']
          }
          ]
        },
        45: {
          name: '广东省第一荣军医院',
          departments: [{
            id: 1,
            name: '康复医学科',
            doctors: ['医生A', '医生B']
          }
          ]
        },
        41: {
          name: '瑞合康复',
          price: 1980,
          descUrl: 'https://cdn.keihong.tech/img/202408241453577.png',
          desc: '【 1980 】元/月·人（包括：检查报告解读+制定本月康复方案+4次/月康复师视频带练指导+多对1小群管理+本月康复效果总结报告+居家运动小工具包）',
          // 康复医学科,关节外科,脊柱外科,神经外科,神经内科
          departments: [{
            id: 1,
            name: '康复医学科',
            doctors: ['陈婷婷', '刘容容', '陈伟明']
          }
          ]
        },
        50: {
          name: '龙脊康',
          // 康复医学科,关节外科,脊柱外科,神经外科,神经内科
          departments: [{
            id: 1,
            name: '康复医学科',
            doctors: ['医生A', '其他']
          }
          ]
        },
        51: {
          name: '东莞市寮步医院',
          departments: [{
            id: 1,
            name: '康复医学科',
            doctors: ['医生A', '其他']
          }
          ]
        },
        2: {
          name: '暨南大学附属顺德医院',
          // 康复医学科,关节外科,脊柱外科,神经外科,神经内科
          departments: [{
            id: 1,
            name: '康复医学科',
            // 方向延 李志强 朱智文 潘冠佳 毕娟
            doctors: ['尹德铭', '何志坚', '胡旭慧', '方向延', '李志强', '朱智文', '潘冠佳', '毕娟',
              '赵建华', '王悦', '邓恩情', '冯宗勤', '何凤意', '李广源']
          },
          {
            id: 2,
            name: '骨一科|创伤骨科',
            doctors: ['吴春辉', '黎凌云', '陈小磊', '谢伟健', '唐迎春', '李康', '杨勇',
              '郑鸿坚', '段礼良', '麦绍声', '张力予', '余君', '李洁云', '谭丹', '陈斯欣',
              '成彩芬', '伍翠仪', '谷珍荣', '黄楚晏', '肖楚涵', '文区']
          },
          {
            id: 3,
            name: '骨二科|脊柱外科',
            doctors: ['宋兴华', '查丁胜', '徐自强', '蔡跃波', '姚华灿', '陈计稳',
              '蔡社兴', '李笑芬', '杨丽', '曹辉', '李翠莲', '吴桂荣', '梁春梅',
              '李文军', '陈俊杰', '叶自由', '李梦婷', '周悦虹', '周坤兰']
          },
          {
            id: 4,
            name: '神经外科',
            doctors: ['王晓东', '吴烁龙', '潘珏恒', '郑颖锋', '马劲翔', '梁金凤',
              '梁敏仪', '陈祝娇', '陈秋婷', '张翠媚', '韩君梅', '赵雅丽', '袁玉霞',
              '沈燕雯', '苏彩霞', '魏雪聪', '陈若茵']
          },
          {
            id: 5,
            name: '神经内科',
            doctors: ['黄立安', '毕伟', '乔宏宇', '朱慧丽', '董大伟', '何深文', '贺丽萍',
              '杨丽', '宋雪珠', '吴玉莹', '陈怡', '樊月映', '王艳军',
              '李晓莹', '陈欢', '吴静文', '胡嘉媚', '黄燕芳', '吴祥雄', '唐耀', '杨延丽',
              '柯世琴', '张小红', '曾翠娟', '罗三妹', '黄文萍', '陈敏', '梁慧诗']
          }
          ]
        },
        37: {
          name: '广东同江医院',
          // 康复医学科,关节外科,脊柱外科,神经外科,神经内科
          departments: [{
            id: 1,
            name: '康复医学科',
            // 方向延 李志强 朱智文 潘冠佳 毕娟
            doctors: ['张容溶', '曾晓晴', '曾洋燕', '潘明明', '孙栋']
          },
          {
            id: 2,
            name: '其他',
            doctors: ['其他']
          }
          ]
        },
        29: {
          name: '广州和平骨科医院',
          // 康复医学科,关节外科,脊柱外科,神经外科,神经内科
          departments: [{
            id: 1,
            name: '康复医学科',
            // 方向延 李志强 朱智文 潘冠佳 毕娟
            doctors: ['李凌缙', '曾祥城', '李小枫', '陈超文']
          },
          {
            id: 2,
            name: '其他',
            doctors: ['其他']
          }
          ]
        },
        47: {
          name: 'U康来互联网医院',
          expertPrice: 500,
          expertPriceUnit: '元/次',
          expertTimeUnit: '元/次',
          desc: '【 专家接诊服务费 】',
          // 康复医学科,关节外科,脊柱外科,神经外科,神经内科
          departments: [{
            id: 1,
            name: '默认医院科室',
            // 方向延 李志强 朱智文 潘冠佳 毕娟
            doctors: ['默认医生']
          }
          ],
          expertDepartments: [{
            id: 1,
            name: '默认医院科室',
            // 方向延 李志强 朱智文 潘冠佳 毕娟
            doctors: ['严嘉健']
          }
          ]
        },
        // 47: {
        //   name: 'U康来互联网医院',
        //   // 康复医学科,关节外科,脊柱外科,神经外科,神经内科
        //   departments: [{
        //     id: 1,
        //     name: '上海长海医院虹口院区',
        //     // 方向延 李志强 朱智文 潘冠佳 毕娟
        //     doctors: ['医生A', '其他']
        //   },
        //   {
        //     id: 2,
        //     name: '上海中医药大学协爱栋安中医医院',
        //     doctors: ['医生A', '其他']
        //   }
        //   ]
        // },
        49: {
          name: '上海中医药大学协爱栋安中医医院',
          // 康复医学科,关节外科,脊柱外科,神经外科,神经内科
          departments: [{
            id: 1,
            name: '康复医学科',
            // 方向延 李志强 朱智文 潘冠佳 毕娟
            doctors: ['医生A', '其他']
          }
          ]
        }
      },
      // 脑梗塞/脑出血/脑损伤/骨科术后/慢性疼痛/运动损伤/脊柱康复/其它
      sicks: ['脑梗塞', '脑出血', '脑损伤', '骨科术后', '慢性疼痛', '运动损伤', '脊柱康复', '其它'],
      selectedSick: '',
      selectedDepartment: null,
      selectedDoctor: null,
      form: {
        count: 1,
        price: 200
        // deposit: 1200
      },
      orderCreateTime: null
    }
  },
  mounted () {
    // if (window.uni) {
    //   const _that = this
    //   window.uni.getEnv(function (res) {
    //     // console.log('当前环境：' + JSON.stringify(res))
    //     _that.envStatus = JSON.stringify(res)
    //   })
    // }

    this.hospitalId = this.$route.query.hospital_id
    const hospitalName = this.$route.query.hospitalName
    this.from = this.$route.query.from
    const phone = this.$route.query.phone
    const name = this.$route.query.name
    const openid = this.$route.query.openid

    if (this.hospitalId) {
      this.hospital = this.hospitals[this.hospitalId]
    }

    if (hospitalName) {
      // 查找 hospitals 中 name === hospitalName 的医院信息
      const findHospitalByName = (hospitals, hospitalName) => {
        for (const key in hospitals) {
          if (hospitals[key].name === hospitalName) {
            return hospitals[key]
          }
        }
        return null // 如果没有找到匹配的医院
      }
      this.hospital = findHospitalByName(this.hospitals, hospitalName)
    }

    // 当前没有医院信息，创建默认的医院信息
    if (!this.hospital) {
      this.hospital = {
        name: hospitalName,
        departments: [{
          id: 1,
          name: '默认科室',
          doctors: ['默认医生']
        }
        ]
      }
    }

    console.log('hospitalId: ' + this.hospitalId)
    console.log('hospital: ' + this.hospital)

    this.isExpert = this.$route.query.expert

    if (this.hospital.price) {
      this.form.price = this.hospital.price
    }
    if (this.isExpert) {
      this.hospital.notShowPic = true
      this.form.price = this.hospital.expertPrice
      this.hospital.departments = this.hospital.expertDepartments
    }

    if (this.hospital.departments.length === 1) {
      this.selectedDepartment = this.hospital.departments[0]
      if (this.selectedDepartment.doctors.length === 1) {
        this.selectedDoctor = this.selectedDepartment.doctors[0]
      }
    }

    this.$refs.container.stopLoading()

    // if (this.hospitalId) {
    //   HospitalDao.getDoctorsForSelect(this.hospitalId).then(response => {
    //     this.hospitals[this.hospitalId].doctors = response
    //     console.log('doctors: ' + response)
    //   })
    // }

    // 判断是否是微信浏览器
    const isWeChatBrowser = () => {
      const userAgent = navigator.userAgent.toLowerCase()
      return userAgent.includes('micromessenger')
    }

    if (this.from === 'wxapp') {
      this.showTitleBar = false
      this.patientMobile = phone
      this.patientName = name
      this.openid = openid
      // this.envStatus = this.openid
    } else {
      if (isWeChatBrowser()) {
        // console.log("This is a WeChat browser");
        this.initWx()
      } else {
        // console.log("This is not a WeChat browser");
      }
    }

    // const ua = window.navigator.userAgent.toLowerCase()
    // if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    //   // alert('是微信浏览器')
    //   // 初始化微信-获取openid
    //   this.initWx()
    // }
  },
  methods: {
    onBackClick () {
      // 重定向到某个url
      if (this.hospital.name === '内蒙古益济堂养老服务有限公司') {
        this.hospital.name = '中国民间中医医药研究开发协会中蒙民族医药研究发展分会'
      }
      window.location.href = 'https://doucan.jianerkanggz.cn/#/home/square?hospital=' + this.hospital.name
    },
    refreshPage () {
      this.showPopup = false
      // location.reload()
      // window.location.href = 'https://doucan.jianerkanggz.cn/#/home/square?hospital=' + this.hospital
      if (this.from === 'wxapp') {
        // 小程序，返回到小程序首页
      } else {
        // 扫码网页版，返回到首页
        this.onBackClick()
      }
    },
    submitPayment () {
      // Add your payment submission logic here
      this.pay()
    },
    async initWx () {
      await this.getAppid()
      const openid = this.$store.state.user.openId
      if (openid) {
        this.openid = openid
      } else {
        await this.getMpOpenID()
      }
    },

    async pay () {
      if (!this.selectedSick) {
        Notify({
          type: 'danger',
          message: '请选择病因'
        })
        return
      }

      if (!this.selectedDepartment) {
        Notify({
          type: 'danger',
          message: '请选择科室'
        })
        return
      }

      if (!this.selectedDoctor) {
        Notify({
          type: 'danger',
          message: '请选择医生'
        })
        return
      }

      if (!this.patientName || !this.patientMobile) {
        Notify({
          type: 'danger',
          message: '请填写姓名和手机号'
        })
        return
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      let fee = 0
      if (this.testMobile.includes(this.patientMobile)) {
        fee = 0.01
      } else {
        fee = this.totalPrice
      }
      const fromActivity = '扫码直接支付-远程康复服务单'
      // const goods = this.hospital + '-' + this.remarks
      var numberUnit = this.isExpert ? '次' : this.hospital.numberUnit ?? '周'
      const goods = this.hospital.name + '-' + '远程康复服务单-' + this.form.count + numberUnit + '-医:' + this.selectedDoctor + '-患:' + this.patientName
      console.log('goods', goods)
      // if (this.isAdmin) {
      //   fromActivity = '客服操作下单' + '-' + this.remark
      //   goods = this.hospital + '-' + this.doctorName + '-' + this.count + '周'
      // }

      // this.remark = '远程康复服务单-' + this.hospital + '-' + this.selectedDepartment.name + '-' + this.selectedDoctor + '-' + this.selectedSick

      this.remark = `远程康复服务单-${this.form.count}${numberUnit}-${this.hospital.name}-${this.selectedDepartment.name}-${this.selectedDoctor}-${this.selectedSick}-${this.patientName}-${this.patientMobile}`
      // this.showPopup = true
      console.log('remark', this.remark)
      // todo 根据 from === 'wxapp' 判断支付的逻辑
      const profile = {
        serveType: 0,
        mobile: this.patientMobile, // 后期mobile从用户授权获得，即订单记录支付者登录的微信账号手机号及实际接受服务患者的手机号
        patientName: this.patientName,
        patientMobile: this.patientMobile,
        orderType: '',
        fee: fee,
        fromActivity: fromActivity,
        goods: goods,
        openId: this.openid,
        doctor: this.selectedDoctor,
        sick: this.selectedSick,
        department: this.selectedDepartment.name,
        hospital: this.hospital.name,
        remark: this.remark
      }
      console.log('提交订单', profile)
      // 后台创建订单
      this.orderCreateTime = DateUtils.formateDate(new Date(), 'YYYY-MM-DD HH:mm:ss')
      const data = await WxPayDao.createOrder(profile, this.from === 'wxapp')
      console.log('支付qrcode', data)
      if (data.code === 1) {
        this.paySignInfo = data.data
        this.outTradeNo = data.data.outTradeNo

        if (this.from === 'wxapp') {
          // this.envStatus = JSON.stringify(this.paySignInfo)
          // 小程序支付
          /**
           * //在微信内，在小程序内。
           * console.log("小程序的支付业务")
           * let wxJson ={} // 微信返回的5个参数
           * wx.miniProgram.navigateTo({url: '"/pages/pay/pay?param=" + wxJson  '});
           * ————————————————
           * 原文链接：https://blog.csdn.net/m0_37572422/article/details/129270882
           */
          this.paySignInfo.orderInfo = {
            outTradeNo: this.outTradeNo,
            orderCreateTime: this.orderCreateTime,
            remark: this.remark
          }
          const wxJson = JSON.stringify(this.paySignInfo)
          const encodedParam = encodeURIComponent(wxJson)
          window.wx.miniProgram.navigateTo({
            url: `/pages/pay_submit_universe/pay_submit_universe?param=${encodedParam}`
          })
        } else {
          // 调起微信支付
          wx.config({
            // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false
            debug: false,
            // 必填，公众号的唯一标识
            appId: this.paySignInfo.sign.appId,
            // 必填，生成签名的时间戳
            timestamp: this.paySignInfo.sign.timeStamp,
            // 必填，生成签名的随机串
            nonceStr: this.paySignInfo.sign.nonceStr,
            // 必填，签名
            signature: this.paySignInfo.sign.paySign,
            // 必填，需要使用的JS接口列表
            jsApiList: ['chooseWXPay']
          })
          wx.ready(() => {
            wx.checkJsApi({
              jsApiList: ['chooseWXPay'],
              success: function (res) {
                console.log('seccess')
                console.log('hskdjskjk', res)
              },
              fail: function (res) {
                console.log('fail')
                console.log(res)
              }
            })
            const _that = this
            wx.chooseWXPay({
              // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              timestamp: this.paySignInfo.sign.timeStamp,
              // 支付签名随机串，不长于 32 位
              nonceStr: this.paySignInfo.sign.nonceStr,
              // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              package: this.paySignInfo.sign.packageValue,
              // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              signType: this.paySignInfo.sign.signType,
              // 支付签名
              paySign: this.paySignInfo.sign.paySign,
              // 支付成功后的回调函数
              success: function (res) {
                if (res.errMsg === 'chooseWXPay:ok') {
                  console.log('支付成功', res)
                  _that.payStatus = true
                  Dialog.alert({
                    message: '支付成功'
                  }).then(() => {
                    // on close
                    // todo 跳转到支付成功页面，显示支付凭证和提示
                    // _that.$router.push(
                    //   '/salepage/20230001/result?outTradeNo=' + _that.outTradeNo
                    // )
                    _that.showPopup = true
                  })
                } else {
                  Dialog({ message: JSON.stringify(res) })
                  _that.payStatus = false
                }
              },
              fail: function (res) {
                Dialog({ message: '支付失败:' + JSON.stringify(res) })
                _that.payStatus = false
              }
            })
          })
          wx.error((err) => {
            alert(err)
          })
        }
      } else {
        console.log('支付失败-订单创建失败', data)
      }
    },
    async getOpenId (code) {
      // this.info = '正在获取getOpenId'
      const url = '/quick/pay/openid?code=' + code

      axios
        .get(url)
        .then((res) => {
          // this.info = JSON.stringify(res)
          this.openid = res.data.data.openid
          console.log('openid = ', res.data.data.openid)
          if (!this.openid) {
            this.getCodeApi()
            return
          }
          this.$store.commit('user/setOpenId', this.openid)
          // const profile = this.$store.state.apply20230001.profile
          // profile.openId = this.openid
          // this.$store.commit('apply20230001/update', profile)
        })
        .catch((err) => {
          console.log('err', err)
          this.getCodeApi()
        })
    },
    getCodeApi () {
      const appid = 'wxcb56667c309e369b'
      const urlNow = encodeURIComponent(window.location.href)
      const scope = 'snsapi_base' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      // const scope = 'snsapi_userinfo' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      const url =
        'https://open.weixin.qq.com' +
        '/connect/oauth2/authorize?appid=' +
        appid +
        '&redirect_uri=' +
        urlNow +
        '&response_type=code&scope=' +
        scope +
        '#wechat_redirect'
      window.location.href = url
    },
    getUrlKey (name) {
      // 获取url 参数
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [undefined, ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    },
    async getAppid () {
      const url = '/quick/pay/appid'
      const res = await axios.get(url)
      console.log('appid', res)
      this.appid = res.data.data.codeName
      this.appsecret = res.data.data.codeValue
    },
    async getMpOpenID () {
      const code = this.getUrlKey('code')
      if (code) {
        this.code = code
        await this.getOpenId(code)
      } else {
        this.getCodeApi()
      }
    },
    onDepartmentSelected () {
      console.log(this.selectedDepartment)
    }
  },
  watch: {
    'form.count' (val) {
      this.amount = val * this.form.price
      console.log('fee:', this.amount)
      // this.$emit('update-fee', this.fee, val)
    }
  },
  computed: {
    totalPrice () {
      return this.form.price * this.form.count
    }
  }
}
</script>
<style scoped>

.logo {
  float: left;
  width: 60px;
  height: 60px;
}

.institution-name {
  float: right;
  width: 100%;
  font-size: 14px;
  margin-left: 16px;
}

.divider {
  height: 1px;
  background-color: #ccc;
  margin: 16px;
}

.inputBoxWrapper {
  margin: 16px;
}

.inputBox {
  border: 1px solid #ccc;
  border-radius: 16px;
}

.textarea-wrapper {
  margin: 16px;
}

.textarea-wrapper .van-field__control {
}

.button-wrapper {
  margin: 16px;

}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
  color: #7e7e7e;
}

.input-wrapper {
  margin: 16px;
}

.popup-wrapper {
  height: 80vh;
}

.success-logo {
  width: 70px;
  height: 70px;
  margin: 0 auto;
}

.success-message {
  text-align: center;
  font-size: 20px;
  margin: 20px 0;
}

.right {
  float: right;
}
</style>
