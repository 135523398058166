<template>
  <div>
    <!--    <div style="position: absolute;top: 16px;right: 16px" @click="onSubmitHistoryClick">提交记录</div>-->
    <!--    <div style="position:absolute;top: 4px;left: 4px;color: rgba(51,51,51,0.26)">{{ test }}</div>-->

    <base-title-bar-scroll-page ref="container" :show-title-bar="!hideTitle" title="患者录入数据">
      <my-img style="width: 100%" url="https://cdn.keihong.tech/img/202411191109635.png"/>
      <van-cell-group>
        <van-cell :title="questionnaire.title"/>
        <van-cell>
          <p>{{ questionnaire.description }}</p>
        </van-cell>
      </van-cell-group>

      <van-form @submit="onSubmit">
        <div v-for="(question, index) in questionnaire.questions" :key="question.id" :data-question-id="question.id">
          <QuestionItem
            v-model="answers[question.id]"
            :question="question"
          />
        </div>
        <div style="padding: 16px">
          <van-button block native-type="submit" round type="primary">提交</van-button>
        </div>
      </van-form>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import MyImg from '@/h5/doucan/components/MyImg.vue'
import QuestionItem from '@/h5/doucan/components/Questionnaire/QuestionItem.vue'
import QuestionnaireDao from '@/h5/doucan/network/QuestionnaireDao'
import { Dialog } from 'vant'

export default {
  name: 'DonglingPatientInfoTable',
  components: {
    QuestionItem,
    MyImg,
    BaseTitleBarScrollPage
  },
  data () {
    return {
      questionnaireId: 24,
      test: '',
      hideTitle: false,
      questionnaire: {
        title: '',
        description: '',
        questions: []
      },
      answers: {}, // 用于存储用户填写的答案
      lastAnswer: null,
      openId: '',
      isWxapp: false
    }
  },
  async created () {
    // 从服务器获取数据

  },
  async mounted () {
    const query = this.$route.query
    // url = `http://192.168.0.119:8094/doucan/#/DonglingPatientInfoTable?
    // from=wxapp
    // &hospitalName=${hospitalName}
    // &phone=${accountStore.profile?.user.phone}
    // &name=${accountStore.profile?.user.realname}
    // &openid=${accountStore.miniOpenId}`

    if (query.from === 'wxapp') {
      this.isWxapp = true
      this.hideTitle = true
      this.openId = query.openid
      this.test = `openid=${query.openid}、phone=${query.phone}`
      if (query.phone === 'undefined') {
        this.test = `openid=${query.openid.substring(query.openid.length - 4)}`
      }
    }
    await this.getNewestAnswer()
    const response = await QuestionnaireDao.load(this.questionnaireId)
    console.log('问卷数据', response)
    this.questionnaire = response
    this.hideLoading()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    async onSubmit () {
      console.log('提交的答案:', this.answers)
      // 检查答案完整性
      const unansweredQuestions = this.questionnaire.questions.filter(
        question => question.required && !this.answers[question.id]
      )
      if (unansweredQuestions.length > 0) {
        // 提示未完成的问题
        const firstUnanswered = unansweredQuestions[0]
        console.log('未填项目', firstUnanswered)
        this.$toast.fail(`请完成必填问题: ${firstUnanswered.order}、${firstUnanswered.content}`)

        // 滚动到第一个未完成的问题
        this.scrollToQuestion(firstUnanswered.id)
        return
      }
      // 上传文件
      // 遍历 answers，检查是否有文件类型问题
      for (const questionId in this.answers) {
        const question = this.questionnaire.questions.find(q => q.id === parseInt(questionId))
        const value = this.answers[questionId]
        // console.log(questionId, value)

        if (question && question.type === 'file') {
          if (value) {
            console.log('有文件', value)
            const uploadedUrls = []
            for (var i in value) {
              if (value[i].status === 'success') {
                uploadedUrls.push(value[i].url)
                continue
              }
              const file = value[i].file
              const result = await this.uploadToAliyun(file, i)
              // var uploadedUrl = result.res.requestUrls[0]
              const requestUrl = result.res.requestUrls[0]
              var uploadedUrl = requestUrl.includes('?uploadId') ? requestUrl.substring(0, requestUrl.indexOf('?')) : requestUrl
              console.log('uploadedUrl', uploadedUrl)
              // const uploadedUrl = await uploadFile(file)
              // 如果 uploadedUrl 是http开头，改为https
              if (uploadedUrl.startsWith('http:')) {
                uploadedUrl = uploadedUrl.replace('http:', 'https:')
                console.log('uploadedUrl https', uploadedUrl)
              }
              uploadedUrls.push(uploadedUrl)
            }

            // 替换文件的 value 为上传后的 URL 数组
            this.answers[questionId] = uploadedUrls
          }
        }
        //   if (Array.isArray(value) && value.every(item => item instanceof File)) {
        //     console.log('有文件-数组')
        //     // 如果是文件数组，逐个上传文件
        //     const uploadedUrls = []
        //
        //     for (var i in value) {
        //       const file = value[i]
        //       const result = await this.uploadToAliyun(file, i)
        //       const uploadedUrl = result.res.requestUrls[0].substring(0, result.res.requestUrls[0].indexOf('?'))
        //
        //       // const uploadedUrl = await uploadFile(file)
        //       uploadedUrls.push(uploadedUrl)
        //     }
        //
        //     // 替换文件的 value 为上传后的 URL 数组
        //     this.answers[questionId] = uploadedUrls
        //   } else if (value instanceof File) {
        //     console.log('有文件-单个')
        //     // 如果是单个文件
        //     const result = await this.uploadToAliyun(value, 0)
        //     const uploadedUrl = result.res.requestUrls[0].substring(0, result.res.requestUrls[0].indexOf('?'))
        //
        //     this.answers[questionId] = [uploadedUrl] // 包装成数组
        //   }
      }
      // this.showPopup = true
      console.log('上传文件', this.answers)
      // 在此处提交答案到服务器
      const body = this.transformData(this.answers)
      console.log('提交答案 body', body)
      const res = await QuestionnaireDao.submitAnswer(body)
      console.log('提交返回结果', res)
      if (res.code === 200) {
        // 提交成功，弹出提示框
        Dialog.alert({
          title: '温馨提示',
          message: '提交成功'
        }).then(() => {
          // on close
          if (this.isWxapp) {
            // 跳转到小程序主页
            window.wx.miniProgram.switchTab({
              url: '/pages/index/index'
            })
          }
        })
      }
    },
    scrollToQuestion (questionId) {
      this.$nextTick(() => {
        const questionElement = document.querySelector(`[data-question-id="${questionId}"]`)
        if (questionElement) {
          questionElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }
      })
    },
    transformData (rawData) {
      // 初始化转换后的数据结构
      const transformedData = {
        questionnaireId: this.questionnaireId,
        userId: '',
        openId: this.openId,
        answers: []
      }

      // 遍历 rawData，将其转换为目标结构
      Object.entries(rawData).forEach(([key, value]) => {
        const questionId = parseInt(key, 10) // 确保 questionId 是数字
        const answer = { questionId }

        if (typeof value === 'string') {
          // 文本回答
          answer.content = value
        } else if (typeof value === 'number') {
          // 单选题
          answer.optionId = value
        } else if (Array.isArray(value)) {
          var isFileUrl = false

          // 判断是否包含 http
          for (const content of value) {
            if (typeof content === 'string' && content.startsWith('http')) {
              answer.content = content
              isFileUrl = true
              break
            }
          }
          if (isFileUrl) {
            answer.content = JSON.stringify(value)
          } else {
            // 多选题
            answer.optionIds = value
          }
        }

        // 将处理后的答案加入 answers 列表
        transformedData.answers.push(answer)
      })

      return transformedData
    },
    onSubmitHistoryClick () {
      // 跳转到 /QuestionnaireHistoryPage
      this.$router.push('/QuestionnaireHistoryPage?openId=' + this.openId)
    },
    async getNewestAnswer () {
      const result = await QuestionnaireDao.getSubmitHistory(this.openId)
      console.log('问卷历史记录', result)
      if (result == null || result.length === 0) {
        console.log('无历史答案')
        return
      }
      const newestQuestionnaires = result[result.length - 1]
      this.lastAnswer = await QuestionnaireDao.getAnswersByResponseId(newestQuestionnaires.responseId)
      console.log('最新答案', this.lastAnswer)
      // 初始化 answers 对象
      this.initializeAnswers(this.lastAnswer.questions)
    },
    initializeAnswers (lastAnswer) {
      console.log('初始化答案', lastAnswer)
      // this.answers = {} // 重置 answers
      lastAnswer.forEach(question => {
        if (question.type === 'text') {
          // 文本类型
          this.answers[question.id] = question.answer || ''
        } else if (question.type === 'single_choice') {
          // 单选类型
          this.answers[question.id] = question.answer || null
        } else if (question.type === 'multiple_choice') {
          // 多选类型
          this.answers[question.id] = question.answer || []
        } else if (question.type === 'file') {
          // 文件url类型
          if (question.answer) {
            question.answer = JSON.parse(question.answer)
            question.answer = this.convertUrlsToUploaderFiles(question.answer)
          }
          this.answers[question.id] = question.answer || []
        }
      })
    },
    convertUrlsToUploaderFiles (urls) {
      return urls.map((url, index) => ({
        name: `文件${index + 1}`, // 可根据实际需求生成文件名
        url: url,
        status: 'success' // 标记为已上传成功
      }))
    },
    async uploadToAliyun (file, index) {
      this.currentUploadImageIndex = index
      const _that = this
      const OSS = require('ali-oss')
      const ossConfig = {
        // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
        region: 'oss-cn-shenzhen',
        // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
        accessKeyId: 'LTAIAcClE56I1mY0',
        accessKeySecret: 'IIPoiW0o3noCHU9bRT5vyAw0EDtem8',
        bucket: 'ihealthy'
      }
      const client = new OSS(ossConfig)
      // console.log(file)
      // file.name 文件名添加时间戳
      const filename = new Date().getTime() + '_' + file.name
      // 分片上传文件
      const s = `questionnaire/${this.questionnaireId}/` + filename
      console.log('上传文件', s)
      const ret = await client.multipartUpload(s, file, {
        progress: async function (p) {
          // console.log('multipartUpload', p)
          const e = {}
          e.percent = parseInt(p * 100)
          console.log(`上传进度:  第${index + 1}张图片 - ${e.percent} - `)

          _that.currentImagePercent = e.percent
          // option.onProgress(e)
        }
      })
      console.log('上传结果', ret)
      if (ret.res.statusCode === 200) {
        // option.onSuccess(ret)
        console.log('上传成功', ret)
        return ret
      } else {
        // vm.disabled = false
        // option.onError('上传失败')
        console.log('上传失败', ret)
        return null
      }
    }

  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
